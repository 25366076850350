import manifest from '../../../icons-manifest.json';

const IS_PROD = process.env.NODE_ENV === 'production';
const PROD_URL = 'https://platform.aiircdn.com/icons/tone/';
const DEV_URL = '/icons/tone/';
const PATH_PREFIX = IS_PROD ? PROD_URL : DEV_URL;

/**
 * Inspiration: https://github.com/tiagoporto/svg-to-inline/blob/main/src/SvgToInline.js
 *
 * A good doc on effective use of attributes, using SVG loading as an example:
 * https://thomaswilburn.github.io/wc-book/ce-attributes.html
 *
 * Order callbacks are initially called:
 * 1. constructor
 * 2. attributeChangedCallback (with oldValue = null)
 * 3. connectedCallback
 */
export default class ToneIcon extends HTMLElement {
    static observedAttributes = ['name'];

    // Rules for constructor:
    // https://github.com/whatwg/dom/issues/522#issuecomment-339118581
    constructor() {
        super();
        //console.log('ToneIcon constructor', this);
    }

    connectedCallback() {
        //console.log('ToneIcon connectedCallback', this);
    }

    attributeChangedCallback(attr, was, value) {
        //console.log('Attribute change', {attr, was, value}, this);

        // Avoid re-rendering an icon already server-side rendered
        const ssrHydration = this.isSsr && !this.isConnected;

        switch (attr) {
            case 'name': {
                if (!value || value === was || ssrHydration) return;
                this.loadSvg(value);
                break;
            }
        }
    }

    loadSvg(name) {
        const path = this.getPathFromName(name);
        if (!path) return;
        ToneIcon.fetchFile(path).then((content) => (this.innerHTML = content));
    }

    getPathFromName(name) {
        // If provided name has no namespace, use 'standard' by default
        const nameParts = name.split('/');
        const prefix = nameParts.length === 1 ? 'standard/' : '';
        const manifestKey = `${prefix}${name}.svg`;
        if (manifest[manifestKey]) {
            return PATH_PREFIX + manifest[manifestKey];
        }
        return null;
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get isSsr() {
        return this.hasAttribute('ssr');
    }

    static async fetchFile(path) {
        try {
            return await (await fetch(path)).text();
        } catch (error) {
            return new Error(error);
        }
    }
}
